<template>
  <a-card :bordered="false">
    <a-tabs defaultActiveKey="1">
      <a-tab-pane tab="查验科月查获清单" key="1">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpanMulti(2)">
                <a-form-item label="录单日期">
                  <a-range-picker @change="onDateChange('queryParam1', ...arguments)" style="width: 200px"
                                  v-model="param1DefaultDate"/>
                  <a-radio-group @change="handleDateChange('queryParam1', ...arguments)" class="date-btns">
                    <a-radio-button value="0">昨天</a-radio-button>
                    <a-radio-button value="1">今天</a-radio-button>
                    <a-radio-button value="2">明天</a-radio-button>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务来源">
                  <a-select
                    showSearch
                    allowClear
                    mode="multiple"
                    placeholder="请选择业务来源"
                    style="width: 100%"
                    v-model="queryParam1['*source']"
                  >
                    <a-select-option v-for="op in sourceOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="货物状态">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择货物状态"
                    style="width: 100%"
                    v-model="queryParam1['commodity_type']"
                  >
                    <a-select-option v-for="op in commodityTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="报关单号">
                  <a-input v-model="queryParam1['%report_num']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="计划号">
                  <a-input v-model="queryParam1['%plan_id']"/>
                </a-form-item>
              </a-col>
              <a-col :xs="6">
                <span class="table-page-search-submitButtons">
                  <a-button type="query" icon="search" @click="$refs.monthTable.refresh(true)">查询</a-button>
                  <a-button type="primary" @click="handleExport('export_seize')">导出</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <s-table
          ref="monthTable"
          size="small"
          :rowKey="function(record) { return getRandomCode(8) }"
          bordered
          :columns="columns1"
          :data="loadData1"
          :scroll="{ x: 'max-content' }">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </s-table>
      </a-tab-pane>
      <a-tab-pane tab="查验点日工作台账" key="2" forceRender>
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpanMulti(2)">
                <a-form-item label="录单日期">
                  <a-range-picker @change="onDateChange('queryParam2', ...arguments)" style="width: 200px"
                                  v-model="param2DefaultDate"/>
                  <a-radio-group @change="handleDateChange('queryParam2', ...arguments)" class="date-btns">
                    <a-radio-button value="0">昨天</a-radio-button>
                    <a-radio-button value="1">今天</a-radio-button>
                    <a-radio-button value="2">明天</a-radio-button>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务来源">
                  <a-select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder="请选择业务来源"
                    style="width: 100%"
                    v-model="queryParam2['*source']"
                  >
                    <a-select-option v-for="op in sourceOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="报关单号">
                  <a-input v-model="queryParam2['%report_num']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="计划号">
                  <a-input v-model="queryParam2['%plan_id']"/>
                </a-form-item>
              </a-col>
              <a-col :xs="6">
                <span class="table-page-search-submitButtons">
                  <a-button type="query" icon="search" @click="$refs.dayTable.refresh(true)">查询</a-button>
                  <a-button type="primary" @click="handleExport('export_daily')">导出</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <s-table
          ref="dayTable"
          size="small"
          :rowKey="function(record) { return getRandomCode(8) }"
          bordered
          :columns="columns2"
          :data="loadData2"
          :scroll="{ x: 'max-content' }">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </s-table>
      </a-tab-pane>
      <a-tab-pane tab="查验场地暂存货物登记表" key="3">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpanMulti(2)">
                <a-form-item label="录单日期">
                  <a-range-picker @change="onDateChange('queryParam3', ...arguments)" style="width: 200px"
                                  v-model="param3DefaultDate"/>
                  <a-radio-group @change="handleDateChange('queryParam3', ...arguments)" class="date-btns">
                    <a-radio-button value="0">昨天</a-radio-button>
                    <a-radio-button value="1">今天</a-radio-button>
                    <a-radio-button value="2">明天</a-radio-button>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务来源">
                  <a-select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder="请选择业务来源"
                    style="width: 100%"
                    v-model="queryParam3['*source']"
                  >
                    <a-select-option v-for="op in sourceOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="报关单号">
                  <a-input v-model="queryParam3['%report_num']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="计划号">
                  <a-input v-model="queryParam3['%plan_id']"/>
                </a-form-item>
              </a-col>
              <a-col :xs="6">
                <span class="table-page-search-submitButtons">
                  <a-button type="query" icon="search" @click="$refs.storageTable.refresh(true)">查询</a-button>
                  <a-button type="primary" @click="handleExport('export_storage')">导出</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <s-table
          size="small"
          :rowKey="function(record) { return getRandomCode(8) }"
          bordered
          ref="storageTable"
          :columns="columns3"
          :data="loadData3"
          :scroll="{ x: 'max-content' }">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </s-table>
      </a-tab-pane>
      <a-tab-pane tab="查询总表" key="4">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpanMulti(2)">
                <a-form-item label="录单日期">
                  <a-range-picker @change="onDateChange('queryParam4', ...arguments)" style="width: 200px"
                                  v-model="param4DefaultDate"/>
                  <a-radio-group @change="handleDateChange('queryParam4', ...arguments)" class="date-btns">
                    <a-radio-button value="0">昨天</a-radio-button>
                    <a-radio-button value="1">今天</a-radio-button>
                    <a-radio-button value="2">明天</a-radio-button>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务来源">
                  <a-select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder="请选择业务来源"
                    style="width: 100%"
                    v-model="queryParam4['*source']"
                  >
                    <a-select-option v-for="op in sourceOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="货物状态">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择货物状态"
                    style="width: 100%"
                    v-model="queryParam4['commodity_type']"
                  >
                    <a-select-option v-for="op in commodityTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="报关单号">
                  <a-input v-model="queryParam4['%report_num']"/>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="计划号">
                  <a-input v-model="queryParam4['%plan_id']"/>
                </a-form-item>
              </a-col>
              <a-col :xs="6">
                <span class="table-page-search-submitButtons">
                  <a-button type="query" icon="search" @click="$refs.totalTable.refresh(true)">查询</a-button>
                  <a-button type="primary" @click="handleExport('export_part')">导出</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <s-table
          size="small"
          ref="totalTable"
          :rowKey="function(record) { return getRandomCode(8) }"
          bordered
          :columns="columns4"
          :data="loadData4"
          :scroll="{ x: 'max-content' }">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
        </s-table>
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getCmsStaticsPage, getCmsMetaOption, exportCmsReport } from '@/api/cms'
import { getRandomCode } from '@/utils/util'
import moment from 'moment'
import { downloadExcel } from '@/api/common'
export default {
  components: {
    STable
  },
  data() {
    return {
      queryParam1: {},
      queryParam2: {},
      queryParam3: {},
      queryParam4: {},
      param1DefaultDate: [moment().startOf('month'), moment().endOf('month')],
      param2DefaultDate: [moment().startOf('month'), moment().endOf('month')],
      param3DefaultDate: [moment().startOf('month'), moment().endOf('month')],
      param4DefaultDate: [moment().startOf('month'), moment().endOf('month')],
      inspectTypeMap: {},
      sourceOps: [],
      commodityTypeOps: [],
      columns1: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '录单时间',
          dataIndex: 'receive_time',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD');
          }
        },
        {
          title: '业务来源',
          dataIndex: 'source_name'
        },
        {
          title: '计划号',
          dataIndex: 'plan_id'
        },
        {
          title: '报关单位',
          dataIndex: 'customs_name'
        },
        {
          title: '经营单位',
          dataIndex: 'operator_name'
        },
        {
          title: '报关单号',
          dataIndex: 'report_num'
        },
        {
          title: '工号',
          dataIndex: 'worker_num'
        },
        {
          title: '箱型*箱量',
          dataIndex: 'box_type_name'
        },
        {
          title: '监管方式',
          dataIndex: 'supervise_name'
        },
        {
          title: '品名',
          dataIndex: 'violation_name'
        },
        {
          title: '件数',
          dataIndex: 'total_num'
        },
        {
          title: '毛重',
          dataIndex: 'total_gross_weight'
        },
        {
          title: '净重',
          dataIndex: 'total_net_weight'
        },
        {
          title: '金额',
          dataIndex: 'money'
        },
        {
          title: '查验方式',
          dataIndex: 'inspect_type_name'
        },
        {
          title: '货物状态',
          dataIndex: 'commodity_type_name'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '进库日期',
          dataIndex: 'purchase_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '出库日期',
          dataIndex: 'release_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        }
      ],
      loadData1: parameter => {
        const params = { ...this.queryParam1 }
        return getCmsStaticsPage('list_seize', Object.assign(parameter, params))
          .then(res => {
            return res
          })
      },
      columns2: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '录单时间',
          dataIndex: 'receive_time',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD');
          }
        },
        {
          title: '业务来源',
          dataIndex: 'source_name'
        },
        {
          title: '计划号',
          dataIndex: 'plan_id'
        },
        {
          title: '报关单位',
          dataIndex: 'customs_name'
        },
        {
          title: '经营单位',
          dataIndex: 'operator_name'
        },
        {
          title: '报关单号',
          dataIndex: 'report_num'
        },
        {
          title: '工号',
          dataIndex: 'worker_num'
        },
        {
          title: '箱型*箱量',
          dataIndex: 'box_type_name'
        },
        {
          title: '监管方式',
          dataIndex: 'supervise_name'
        },
        {
          title: '品名',
          dataIndex: 'violation_name'
        },
        {
          title: '件数',
          dataIndex: 'total_num'
        },
        {
          title: '毛重',
          dataIndex: 'total_gross_weight'
        },
        {
          title: '净重',
          dataIndex: 'total_net_weight'
        },
        {
          title: '金额',
          dataIndex: 'money'
        },
        {
          title: '查验方式',
          dataIndex: 'inspect_type_name'
        },
        {
          title: '货物状态',
          dataIndex: 'commodity_type_name'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '进库日期',
          dataIndex: 'purchase_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '出库日期',
          dataIndex: 'release_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        }
      ],
      loadData2: parameter => {
        const params = { ...this.queryParam2 }
        return getCmsStaticsPage('list_daily', Object.assign(parameter, params))
          .then(res => {
            return res
          })
      },
      columns3: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '录单时间',
          dataIndex: 'receive_time',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD');
          }
        },
        {
          title: '业务来源',
          dataIndex: 'source_name'
        },
        {
          title: '计划号',
          dataIndex: 'plan_id'
        },
        {
          title: '报关单位',
          dataIndex: 'customs_name'
        },
        {
          title: '经营单位',
          dataIndex: 'operator_name'
        },
        {
          title: '报关单号',
          dataIndex: 'report_num'
        },
        {
          title: '工号',
          dataIndex: 'worker_num'
        },
        {
          title: '箱型*箱量',
          dataIndex: 'box_type_name'
        },
        {
          title: '监管方式',
          dataIndex: 'supervise_name'
        },
        {
          title: '品名',
          dataIndex: 'violation_name'
        },
        {
          title: '件数',
          dataIndex: 'total_num'
        },
        {
          title: '毛重',
          dataIndex: 'total_gross_weight'
        },
        {
          title: '净重',
          dataIndex: 'total_net_weight'
        },
        {
          title: '金额',
          dataIndex: 'money'
        },
        {
          title: '查验方式',
          dataIndex: 'inspect_type_name'
        },
        {
          title: '货物状态',
          dataIndex: 'commodity_type_name'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '进库日期',
          dataIndex: 'purchase_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '出库日期',
          dataIndex: 'release_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        }
      ],
      loadData3: parameter => {
        const params = { ...this.queryParam3 }
        return getCmsStaticsPage('list_storage', Object.assign(parameter, params))
          .then(res => {
            return res
          })
      },
      columns4: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '录单时间',
          dataIndex: 'receive_time',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD');
          }
        },
        {
          title: '业务来源',
          dataIndex: 'source_name'
        },
        {
          title: '计划号',
          dataIndex: 'plan_id'
        },
        {
          title: '报关单位',
          dataIndex: 'customs_name'
        },
        {
          title: '经营单位',
          dataIndex: 'operator_name'
        },
        {
          title: '报关单号',
          dataIndex: 'report_num'
        },
        {
          title: '工号',
          dataIndex: 'worker_num'
        },
        {
          title: '箱型*箱量',
          dataIndex: 'box_type_name'
        },
        {
          title: '监管方式',
          dataIndex: 'supervise_name'
        },
        {
          title: '品名',
          dataIndex: 'violation_name'
        },
        {
          title: '件数',
          dataIndex: 'total_num'
        },
        {
          title: '毛重',
          dataIndex: 'total_gross_weight'
        },
        {
          title: '净重',
          dataIndex: 'total_net_weight'
        },
        {
          title: '金额',
          dataIndex: 'money'
        },
        {
          title: '查验方式',
          dataIndex: 'inspect_type_name'
        },
        {
          title: '货物状态',
          dataIndex: 'commodity_type_name'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '进库日期',
          dataIndex: 'purchase_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '出库日期',
          dataIndex: 'release_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        }
      ],
      loadData4: parameter => {
        const params = { ...this.queryParam4 }
        return getCmsStaticsPage('list_part', Object.assign(parameter, params))
          .then(res => {
            return res
          })
      }
    }
  },
  created() {
    this.queryParam1['@receive_time'] = [moment().startOf('month').valueOf(), moment().endOf('month').valueOf()]
    this.queryParam2['@receive_time'] = [moment().startOf('month').valueOf(), moment().endOf('month').valueOf()]
    this.queryParam3['@receive_time'] = [moment().startOf('month').valueOf(), moment().endOf('month').valueOf()]
    this.queryParam4['@receive_time'] = [moment().startOf('month').valueOf(), moment().endOf('month').valueOf()]
    getCmsMetaOption('inspect_type').then(v => {
      v.forEach(i => {
        this.inspectTypeMap[i.value] = i.name
      })
    })
    getCmsMetaOption('source').then(v => {
      this.sourceOps = v
    })
    getCmsMetaOption('commodity_type').then(v => {
      this.commodityTypeOps = v
    })
  },
  methods: {
    moment,
    getRandomCode,
    handleDateChange(params, e) {
      const moments = []
      if (e.target.value === '0') {
        moments.push(moment().subtract(1, 'days').startOf('day'))
        moments.push(moment().subtract(1, 'days').endOf('day'))
      } else if (e.target.value === '1') {
        moments.push(moment().startOf('day'))
        moments.push(moment().endOf('day'))
      } else if (e.target.value === '2') {
        moments.push(moment().add(1, 'days').startOf('day'))
        moments.push(moment().add(1, 'days').endOf('day'))
      }
      switch (params) {
        case 'queryParam1':
          this.param1DefaultDate = moments
          break;
        case 'queryParam2':
          this.param2DefaultDate = moments
          break;
        case 'queryParam3':
          this.param3DefaultDate = moments
          break;
        case 'queryParam4':
          this.param4DefaultDate = moments
          break;
      }
      this.onDateChange(params, moments)
    },
    onDateChange(params, date, dateString) {
      const moments = []
      if (date[0]) {
        moments.push(date[0].startOf('day').valueOf())
        moments.push(date[1].endOf('day').valueOf())
      }
      this[params]['@receive_time'] = moments
    },
    handleExport(type) {
      let params = {}
      switch (type) {
        case 'export_seize':
          params = { ...this.queryParam1 }
          break
        case 'export_daily':
          params = { ...this.queryParam2 }
          break
        case 'export_storage':
          params = { ...this.queryParam3 }
          break
        case 'export_part':
          params = { ...this.queryParam4 }
          break
      }
      // window.location.href = process.env.VUE_APP_API_BASE_URL + '/cms/cms_statics/' + type
      exportCmsReport(type, params).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    }
  }
}
</script>
<style scoped>
  .date-btns{
    width: 240px;
    padding-left: 0!important;
    padding-right: 0!important;
  }
</style>
